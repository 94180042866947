import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';
import { useZoneContext } from '../../context/ZoneContext';
import { BookletSelectedCompanyItem } from './BookletSelectedCompanyItem';
import { useMutation } from '@apollo/client';
import { sendOrderPdfLink } from '../../queries/booklet';
import { createCompletedOrdersMutation, sendEmail } from '../../queries/queries';
import { Button } from '../../common/Form';
import { MdOutlineMail, MdSearch } from 'react-icons/md';
import { useParams } from 'react-router';
import HistoryTab from './popUpTabs/HistoryTab';
import { useCompaniesTableContext } from '../../context/CompaniesTableContext';
import { usePreviewContext } from '../../context/PreviewContext';

const { REACT_APP_BASE_URL } = process.env;

function BookletSelectedCompaniesPopup(props) {
  const { booklet, company, companyHeight, companyIndex, bookletIndex, coupons } = props;

  const { isDateActive, getDateClassName } = useCompaniesTableContext();
  const { selectedCompaniesInBooklet, setSelectedCompaniesInBooklet } = useZoneContext();
  const [createCompletedOrders, { loading: createLoading, error: createError }] = useMutation(createCompletedOrdersMutation);
  const [sendOrderPdfLinkMutation, { loading: sendOrderPdfLinkLoading, error: sendOrderPdfLinkError }] = useMutation(sendOrderPdfLink);
  const [sendEmailMutation, { loading: sendEmailLoading, error: sendEmailError }] = useMutation(sendEmail);

  const presetTitle = 'Värdebevis till {company}';
  const presetMessage = `Här kommer ett värdebevis för häftet {booklet}.\n\n` +
    'Klicka på knappen nedan för att gå till sidan där du kan ladda ner PDF:en.';

  const tabs = {
    MESSAGE: 'messageTab',
    HISTORY: 'historyTab'
  }

  const [isCompanyCheckboxHovered, setIsCompanyCheckboxHovered] = useState(false);
  const [error, setError] = useState('');

  const [isMessageWithPdf, setIsMessageWithPdf] = useState(false);

  const [titleWithPdfToCompany, setTitleWithPdfToCompany] = useState(presetTitle);
  const [messageWithPdfToCompany, setMessageWithPdfToCompany] = useState(presetMessage);
  const [titleToCompany, setTitleToCompany] = useState('');
  const [messageToCompany, setMessageToCompany] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabs.MESSAGE)

  const { zone } = useParams();

  const isFirstCellItem = companyIndex === 0 && bookletIndex === 0;

  const isCompanySelected = selectedCompaniesInBooklet?.some(
    item => item.company._id === company._id && item.booklet._id === booklet._id
  );

  const handleCloseCompanyPopup = () => {
    setSelectedCompaniesInBooklet([]);
    setError('');
  }

  const toggleSelectedCompany = (company, booklet) => {
    setSelectedCompaniesInBooklet(prev => {
      const existingIndex = prev.findIndex(
        item => item.company._id === company._id && item.booklet._id === booklet._id
      );
      if (existingIndex >= 0) {
        return prev.filter((_, index) => index !== existingIndex);
      } else {
        return [...prev, { company, booklet }];
      }
    });
  };

  useEffect(() => {
    if (createError) {
      setError('Något gick fel vid skapandet av värdebeviset');
    } else if (sendOrderPdfLinkError) {
      setError('Något gick fel när värdebeviset skulle skickas');
    } else if (sendEmailError) {
      setError('Något gick fel när meddelandet skulle skickas');
    }
  }, [createError, sendOrderPdfLinkError, sendEmailError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isMessageWithPdf) {
        // Group selected companies by their booklet
        const groupCompaniesByBooklet = selectedCompaniesInBooklet.reduce((acc, curr) => {
          if (!acc[curr.booklet._id]) {
            acc[curr.booklet._id] = {
              booklet: curr.booklet,
              companies: []
            };
          }
          acc[curr.booklet._id].companies.push(curr.company);
          return acc;
        }, {});

        // Process each booklet group separately
        for (const [bookletId, group] of Object.entries(groupCompaniesByBooklet)) {
          // Create completed orders for all companies in this booklet
          const { data } = await createCompletedOrders({
            variables: {
              bookletId,
              amount: group.companies.length
            }
          });

          // Send email to each company with their specific order details
          for (const [index, company] of group.companies.entries()) {
            await sendOrderPdfLinkMutation({
              variables: {
                bookletId,
                orderCode: data.createCompletedOrders[index].code,
                orderId: data.createCompletedOrders[index]._id,
                emails: company.adminEmails,
                portalUrlForEmails: `${REACT_APP_BASE_URL}/`,
                title: titleWithPdfToCompany.replace(/{booklet}/g, group.booklet.title).replace(/{company}/g, company.name),
                message: messageWithPdfToCompany.replace(/\n/g, '<br />').replace(/{booklet}/g, group.booklet.title).replace(/{company}/g, company.name),
                companyId: company._id,
                zoneId: zone
              }
            });
          }
        }

        handleCloseCompanyPopup();
        setMessageWithPdfToCompany(presetMessage);
      } else {
        // Simple email sending logic
        for (const item of selectedCompaniesInBooklet) {
          await sendEmailMutation({
            variables: {
              emails: item.company.adminEmails,
              title: titleToCompany.replace(/{booklet}/g, item.booklet.title).replace(/{company}/g, item.company.name),
              message: messageToCompany.replace(/\n/g, '<br />').replace(/{booklet}/g, item.booklet.title).replace(/{company}/g, item.company.name),
              zoneId: zone,
              bookletId: item.booklet._id,
              companyId: item.company._id
            }
          });
        }
        handleCloseCompanyPopup();
      }
    } catch (error) {
      console.log(error);
      setError('Något gick fel när meddelandet skulle skickas');
    }
  };

  const sortedCompanies = [...selectedCompaniesInBooklet].sort(
    (a, b) => a.company.name.localeCompare(b.company.name)
  );

  return (
    <>
      {/* {isOpen && <div className='pop-up-overlay'></div>} */}
      {isFirstCellItem && selectedCompaniesInBooklet.length > 0 && (
        <div className='pop-up-overlay'></div>
      )}

      {/* Company checkbox container */}
      <div className={`pop-up-btn  ${getDateClassName(isDateActive(booklet.buyableFrom, booklet.buyableTo))}`}
        style={{ top: -companyHeight - 4 }}
      >
        <div className='select-company-and-preview'>
          <div className={`company-checkbox-container ${isCompanySelected ? 'selected' : ''}`}
            style={{ zIndex: selectedCompaniesInBooklet.length > 0 ? 100 : 0 }}
            onMouseEnter={() => setIsCompanyCheckboxHovered(true)}
            onMouseLeave={() => setIsCompanyCheckboxHovered(false)}
          >
            {(isCompanyCheckboxHovered || isCompanySelected) ? (
              <label className="company-checkbox">
                <input
                  type="checkbox"
                  onChange={() => toggleSelectedCompany(company, booklet)}
                  checked={isCompanySelected}
                />
                <span className={isCompanySelected ? 'checked' : ''}>
                  {isCompanySelected && (
                    <span className="check-icon">
                      <FaCheck size={14} />
                    </span>
                  )}
                </span>
              </label>
            ) : (
              <MdOutlineMail size={20} opacity={0.5} />
            )}
          </div>
          <PreviewButton booklet={booklet} companyId={coupons[0].companyId}></PreviewButton>
        </div>
      </div>

      {/* Company popup content */}

      {isFirstCellItem && selectedCompaniesInBooklet.length > 0 &&
        <div className='pop-up'>
          <div className='pop-up-tabs'>
            <div>
              <button className={`tab-button ${selectedTab === tabs.MESSAGE ? 'active' : ''}`}
                onClick={() => setSelectedTab(tabs.MESSAGE)}>Allmänt</button>
              <button className={`tab-button ${selectedTab === tabs.HISTORY ? 'active' : ''}`}
                onClick={() => setSelectedTab(tabs.HISTORY)}>Historik</button>
            </div>
            <div className='close'
              onClick={() => handleCloseCompanyPopup()}
            ><GrClose /></div>
          </div>
          <div className='pop-up-content'>
            <div className='company-popup'>
              {selectedTab === tabs.MESSAGE ? (
                <>
                  <div className="section">
                    <h3>Meddelande till företag</h3>
                    <div className='company-list'>
                      {sortedCompanies.map((item, index) => (
                        <BookletSelectedCompanyItem
                          key={index}
                          company={item.company}
                          booklet={item.booklet}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="section">
                    <form onSubmit={handleSubmit} style={{ opacity: 1 }}>
                      {isMessageWithPdf ? (
                        <>
                          <input
                            type="text"
                            value={titleWithPdfToCompany}
                            onChange={(e) => setTitleWithPdfToCompany(e.target.value)}
                            placeholder="Ange titel"
                            required
                          />
                          <textarea
                            className="comment-box"
                            value={messageWithPdfToCompany}
                            onChange={(e) => setMessageWithPdfToCompany(e.target.value)}
                            required
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            value={titleToCompany}
                            onChange={(e) => setTitleToCompany(e.target.value)}
                            placeholder="Ange titel"
                            required
                          />
                          <textarea
                            className="comment-box"
                            value={messageToCompany}
                            onChange={(e) => setMessageToCompany(e.target.value)}
                            placeholder='Meddelande...'
                            required
                          />
                        </>
                      )}

                      <div className="include-pdf-checkbox-container company-checkbox-container">
                        <label className="company-checkbox include-pdf-checkbox">
                          <input
                            type="checkbox"
                            checked={isMessageWithPdf}
                            onChange={(e) => setIsMessageWithPdf(e.target.checked)}
                            className='company-checkbox'
                          />
                          <span className={isMessageWithPdf ? 'checked' : ''}>
                            {isMessageWithPdf && (
                              <span className="check-icon">
                                <FaCheck size={14} />
                              </span>
                            )}
                          </span>
                          <small>Skicka med värdebevis (PDF)</small>
                        </label>
                      </div>
                      <Button
                        type="submit"
                        className='primary company-popup-button'
                        loading={sendOrderPdfLinkLoading || sendEmailLoading || createLoading}
                      >
                        Skicka
                      </Button>
                    </form>
                  </div>
                  {error && (
                    <div className="error">
                      {error}
                    </div>
                  )}
                </>
              ) : (
                <HistoryTab zoneId={zone} isReview={false} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  )
}

const PreviewButton = ({ booklet, companyId }) => {
  const { isPreviewOpen, companyToPreview, setCompanyToPreview } = usePreviewContext();

  const handleSetCompanyPreview = () => {
    setCompanyToPreview({
      companyId: companyId,
      bookletId: booklet._id,
    });
  };

  return (
    <div className="preview">
      <button
        className={`preview-button ${isPreviewOpen && (companyToPreview?.companyId === companyId?._id && companyToPreview?.bookletId === booklet?._id) ? 'active' : ''}`}
        onClick={handleSetCompanyPreview}
      >
        <MdSearch size={24} />
      </button>
    </div>
  );
}

export default BookletSelectedCompaniesPopup;
