import Modal from "common/Modal";
import React, { useContext, useRef, useState } from "react";

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    return {
        show,
        setShow,
    }
};

type ModalContextType = {
    showConfirmation: (title: string, message: string) => Promise<boolean>,
};

type ConfirmationModalContextProviderProps = {
    children: React.ReactNode
}

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (props) => {
    const { setShow, show } = useModalShow();
    const resolver = useRef<((value: boolean) => void) | undefined>(undefined);
    const [content, setContent] = useState<{ title: string, message: string } | null>();

    const handleShow = (title: string, message: string): Promise<boolean> => {
        setContent({
            title,
            message
        });
        setShow(true);
        return new Promise<boolean>((resolve) => {
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        showConfirmation: handleShow
    };

    const handleOk = () => {
        resolver.current && resolver.current(true);
        setShow(false);
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        setShow(false)
    };

    return (
        <ConfirmationModalContext.Provider value={modalContext}>
            {props.children}

            {show && (
                <div className="confirmation-pop-up-overlay">
                    <div className="confirmation-modal">
                        <div className="confirmation-modal-body">
                            <h3>{content?.title}</h3>
                            <p>{content?.message || 'Är du säker på att du vill utföra ändringen?'}</p>
                        </div>
                        <div className="confirmation-modal-footer">
                            <button className="secondary" onClick={handleCancel}>Avbryt</button>
                            <button className="primary" onClick={handleOk}>Ja</button>
                        </div>
                    </div>
                </div>
            )}
        </ConfirmationModalContext.Provider>
    )
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { ConfirmationModalContextProvider, useConfirmationModalContext };