import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { updateMyUserMutation, getMyUserQuery, deleteMyUserMutation } from '../../queries/queries';
import { Field, Button } from '../../common/index';
import { Link, useHistory } from 'react-router-dom';
import { useNavigationContext } from '../../context/NavigationContext';

interface MyUserFormProps {
  logout: () => void;
}

interface MyUser {
  firstname: string;
  lastname: string;
  password?: string;
}

function MyUserForm(props: MyUserFormProps) {
  const { logout } = props;
  // Add email as props or get it directly from query MyUser, or something
  const [user, setUser] = useState<MyUser>({ firstname: '', lastname: '' });
  const [message, setMessage] = useState<string | null>(null);
  const [updateMyUser, { loading: updateMyUserLoading, error: updateMyUserError }] = useMutation(updateMyUserMutation);
  const [deleteMyUser, { loading: deleteMyUserLoading, error: deleteMyUserError }] = useMutation(deleteMyUserMutation);
  const { data: userData, refetch: refetchUser } = useQuery(getMyUserQuery);
  const history = useHistory();

  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  useEffect(() => {
    if (userData) {
      setUser({ firstname: userData.user.firstname, lastname: userData.user.lastname });
    }
  }, [userData]);

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (updateMyUserLoading) {
      console.log('Loading. Double submit canceled.')
      return null;
    }

    const vars: any = {
      variables: {
        firstname: user.firstname,
        lastname: user.lastname,
      },
    };

    if (user.password && user.password.length > 0) {
      vars.variables.password = user.password;
    }

    updateMyUser(vars)
      .then(result => {
        console.log('result', result);
        setMessage('Dina ändringar är sparade');
        setUser({ firstname: user.firstname, lastname: user.lastname });
        refetchUser();
      })
      .catch(useMutationError => {
        console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors)
      });
  }

  const deleteMe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //    if (password !== '') {
    const isConfirm = window.confirm('Är du säker på att du vill ta bort ditt konto?');
    console.log(isConfirm);
    if (isConfirm) {
      deleteMyUser()//{ variables: { currentPassword: password } })
        .then(result => {
          console.log('result', result);
          logout();
          history.push('/login');
        })
        .catch(useMutationError => {
          console.log('regErr', useMutationError, useMutationError && useMutationError.graphQLErrors);
        });
    }
    // } else {
    //   alert('Du måste ange lösenord för att ta bort ditt konto');
    // }
  };

  //  const ss = (v) => setUser({ ...user, ...v });
  const ss = (v: any) => { setMessage(null); setUser({ ...user, ...v }) };

  return (
    <div id="my_user" className="container">
      <div className="goBack">
        <Button className="secondary" onClick={() => history.goBack()}>Tillbaka</Button>
      </div>
      <div className="box md">
        <h3>Redigera mina uppgifter</h3>

        <form onSubmit={(e) => { submitForm(e); }}>
          {console.log('updateMyUserLoading', updateMyUserLoading)}

          {/* <p>E-postadress: {userData && userData.user.email}</p> */}
          <Field type="text" x2 name="email" title="E-postadress (kan ej ändras)" value={userData && userData.user.email} onChange={ss} />
          <Field type="text" x2 name="firstname" title="Förnamn" value={user.firstname} onChange={ss} />
          <Field type="text" x2 name="lastname" title="Efternamn" value={user.lastname} onChange={ss} />
          {/*<Field type="password" x2 name="password" title="Lösenord" value={user.password} onChange={ss} />*/}

          {updateMyUserError && updateMyUserError.graphQLErrors.map(({ message }, i) => (
            <p className="error" key={i}>{message}</p>
          ))}
          {message && <p className="message">{message}</p>}
          <Button className="primary lg" loading={updateMyUserLoading} type="submit">Spara ändringar</Button>
        </form>
        <Link className="text primary new-pass myuser" to="/changepassword">Ändra lösenord</Link>
      </div>
      <div className="box md">
        <h3>Ta bort konto</h3>
        <form onSubmit={deleteMe}>
          {/* <span className='flex flex-col my'>
            <label htmlFor="">Ange ditt lösenord</label>
            <input type="password" name="password" title="Lösenord"
              onChange={(e) => setPassword(e.target.value)} value={password}
            />
          </span> */}
          {deleteMyUserError && deleteMyUserError.graphQLErrors.map(({ message }, i) => (
            <p className="error" key={i}>{message}</p>
          ))}
          {message && <p className="message">{message}</p>}
          <Button className="primary lg" loading={deleteMyUserLoading} type="submit">Ta bort konto</Button>
        </form>
      </div>
    </div>
  );
}

export default MyUserForm;
