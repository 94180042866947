import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { addBookletMutation, categoriesInBookletQuery, getBookletsInZoneQuery, updateBookletMutation } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';
import BookletGeneratePDF from './BookletGeneratePDF';
import BookletsPreviousOrderBatches from './BookletsPreviousOrderBatches';
import Select from 'react-select';
import FieldWrapper from '../../common/FieldWrapper';
import PaperBookletsCategories from './PaperBookletsCategories';
import { useQuery } from '@apollo/client';

function BookletsForm(props) {
  const { content, onComplete, associations, zoneId } = props;
  const { data: bookletCategories } = useQuery(categoriesInBookletQuery, { variables: { bookletId: content?._id } });

  const [sContent, setSContent] = useState(content);

  useEffect(() => {
    setSContent({
      ...content,
      zoneId: zoneId,
      // Default associations where zoneId matches (if new booklet)
      purchasableThroughAssociations: content?._id
        ? content.purchasableThroughAssociations
        : associations?.filter((a) => a.zoneId === zoneId).map((a) => a._id),
      categorySettings: content?.categorySettings?.map(({ __typename, ...rest }) => rest) || [] // This could most likely be removed when typescript is implemented
    });
  }, [associations, content, zoneId]);

  const ss = (b) => setSContent({ ...sContent, ...b });

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate) ? '' : parsedDate.toISOString().slice(0, 10);
  }

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} parent="zoneId" get={getBookletsInZoneQuery} add={addBookletMutation} update={updateBookletMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" req name="title" title="Titel på häfte *" value={sContent && sContent.title} onChange={ss} />
            <Field type="number" req name="price" title="Pris" value={sContent && sContent.price} onChange={ss} />
            <Field type="admins" x2 name="adminEmails" title="Administratörer" value={sContent && sContent.adminEmails} onChange={ss} />
          </Group>
          <Group title="Försäljning">
            <Field req type="date" name="buyableFrom" title="Startdatum *" value={sContent && formatDate(sContent.buyableFrom)} onChange={ss} />
            <Field req type="date" name="buyableTo" title="Slutdatum *" value={sContent && formatDate(sContent.buyableTo)} onChange={ss} />
            <FieldWrapper name="associations" title="Säljbart av föreningar" x2>
              <Select
                isMulti
                options={associations && associations.map((a) => ({ value: a._id, label: a.name }))}
                value={sContent && sContent.purchasableThroughAssociations && sContent.purchasableThroughAssociations.map((id) => {
                  const association = associations.find((a) => a._id === id);
                  return {
                    value: id,
                    label: association ? association.name : ''
                  };
                })}
                placeholder="Välj föreningar..."
                onChange={(selected) => ss({ purchasableThroughAssociations: selected?.map((s) => s.value) })}
              />
            </FieldWrapper>
          </Group>
          <Group title="Giltighet">
            <Field req type="date" name="validFrom" title="Startdatum *" value={sContent && formatDate(sContent.validFrom)} onChange={ss} />
            <Field req type="date" name="validTo" title="Slutdatum *" value={sContent && formatDate(sContent.validTo)} onChange={ss} />
          </Group>
          <Group title="Utseende">
            <Field type="text" name="colorLeft" title="Färg på vänster sida" value={sContent && sContent.colorLeft} onChange={ss} />
            <Field type="text" name="colorRight" title="Färg på höger sida" value={sContent && sContent.colorRight} onChange={ss} />
            <Field type="text" name="colorFont" title="Färg på text" value={sContent && sContent.colorFont} onChange={ss} />
            <Field type="image" name="logoURL" title="Logotyp (max 1MB)" value={sContent && sContent.logoURL} onChange={ss} />
          </Group>
          <Group title="Pappershäfte">
            <Field type="textarea" name="textInsidePrintedBooklet" title="Text på insida" value={sContent && sContent.textInsidePrintedBooklet} onChange={ss} rows={9} />
            <Field type="image" name="imageOfBookletInAppURL" title="Bild på häfte i appen (max 1MB)" value={sContent && sContent.imageOfBookletInAppURL} onChange={ss} />
            <PaperBookletsCategories
              categories={bookletCategories?.categoriesInBooklet}
              categorySettings={sContent?.categorySettings}
              setCategorySettings={(newCategorySettings) => ss({
                categorySettings: newCategorySettings
              })}
            />
          </Group>
          <Group title="Generera värdebevis">
            <BookletGeneratePDF booklet={sContent} />
          </Group>
          <Group title="Tidigare ordrar">
            <BookletsPreviousOrderBatches booklet={sContent} />
          </Group>
        </Form>
      </td>
    </tr>
  )
}

BookletsForm.propTypes = {
  content: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
  associations: PropTypes.array.isRequired,
}

BookletsForm.defaultProps = {
  content: null,
}

export default BookletsForm;
