import gql from 'graphql-tag';

// QUERIES

const getCouponPositionsQuery = gql`
  {
    positions: couponPositions {
      _id
      key
      name
    }
  }
`;

const getCouponsQuery = gql`
  query ($companyId: ObjectId!) {
    coupons: couponsInCompany(companyId: $companyId) {
      _id
      companyId
      imageURL
      discount
      smallTitle
      productLink
      terms
      description
      code
      position
      codeType
      estimatedSavings
      usedCouponsResult {
        totalAmount
        booklets {
          _id
          amount
        }
      }
  }
}
`;

// MUTATIONS

const addCouponMutation = gql`
  mutation(
    $companyId: ObjectId!
    $imageURL: String
    $discount: String!
    $smallTitle: String!
    $productLink: String
    $terms: String
    $description: String
    $code: String
    $position: Int
    $codeType: String
    $estimatedSavings: Float
    ) {
    createCoupon(
      companyId: $companyId
      imageURL: $imageURL
      discount: $discount
      smallTitle: $smallTitle
      productLink: $productLink
      terms: $terms
      description: $description
      code: $code
      position: $position
      codeType: $codeType
      estimatedSavings: $estimatedSavings
      ) {
        smallTitle
    }
  }
`;

const updateCouponMutation = gql`
  mutation(
    $_id: ObjectId!
    $imageURL: String
    $discount: String
    $smallTitle: String!
    $productLink: String
    $terms: String
    $description: String
    $code: String
    $position: Int
    $codeType: String
    $estimatedSavings: Float
    ) {
    updateCoupon(
      _id: $_id,
      imageURL: $imageURL
      discount: $discount
      smallTitle: $smallTitle
      productLink: $productLink
      terms: $terms
      description: $description
      code: $code
      position: $position
      codeType: $codeType
      estimatedSavings: $estimatedSavings
      ) {
        smallTitle
    }
  }
`;



export {
  getCouponPositionsQuery,
  getCouponsQuery,
  addCouponMutation,
  updateCouponMutation
};
