import React, { useState } from 'react';
import PropTypes from 'prop-types';


function sortList(arr, sort, totalUsedCoupons = null, categoriesQuery = null ) {
  const { sortBy, sortOrder } = sort;

  if (totalUsedCoupons || categoriesQuery) {
    return [].concat(arr).sort((a, b) => {
      let aValue = a[sortBy];
      let bValue = b[sortBy];
  
      if (sortBy === 'totalUsedCoupons' && totalUsedCoupons) {
        aValue = totalUsedCoupons[a._id] || 0;
        bValue = totalUsedCoupons[b._id] || 0;
      }
  
      if (sortBy === 'categoryId' && categoriesQuery) {
        const categoryA = categoriesQuery.categories.find(category => category._id === a.companyCategoryId);
        const categoryB = categoriesQuery.categories.find(category => category._id === b.companyCategoryId);
        
        aValue = categoryA ? categoryA.name : '';
        bValue = categoryB ? categoryB.name : '';
      }
  
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.toLowerCase() > bValue.toLowerCase() ? sortOrder : sortOrder * -1;
      }
      
      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue > bValue ? sortOrder : sortOrder * -1;
      }
  
      return 0;
    });
  }

  return [].concat(arr).sort((a, b) => (
    (typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : (typeof a[sortBy] === "number" ? a[sortBy] : -1)) >
    (typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : (typeof b[sortBy] === "number" ? b[sortBy] : -1))
      ? sortOrder : sortOrder * -1));
}

sortList.propTypes = {
  obj: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  totalUsedCoupons: PropTypes.object,
  categoriesQuey: PropTypes.object
};

function setSortProp(name, sort){
  sort.setSortBy(name)
  sort.setSortOrder(sort.sortOrder)
}

function SortButton(props) {
  const { sort, children, name } = props;
  
  //If name is same. Change order direction
  if (name == sort.sortBy){
    sort.sortOrder = (sort.sortOrder == 1) ? -1 : 1;
    //If order is a-z the button will flip 180 degres and vice verca backwards
    // if(sort.sortOrder == -1){
    //   return "hej"
    // }if(sort.sortOrder == 1){
    //   return "Då"
    // }
  }

  return (<button className="BtnSort" type="button" onClick={() => setSortProp(name, sort)}>{children}</button>);
}

SortButton.propTypes = {
  children: PropTypes.any.isRequired,
  sort: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

function EditButton(props) {
  const { size, setEditing, children } = props;
  return (<button className={`primary ${size}`} type="button" onClick={setEditing}>{children}</button>);
}

EditButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.any.isRequired,
  setEditing: PropTypes.func.isRequired,
};

EditButton.defaultProps = {
  size: 'md',
};

function EmptyList(props) {
  const { empty } = props;
  if (empty) {
    return (<tr><td colSpan="100" className="table-empty">Här var det visst tomt!</td></tr>);
  }
}

EmptyList.propTypes = {
  empty: PropTypes.bool.isRequired,
};


function LoadingTable(props) {
  const { loading, error } = props;
  if (loading) {
    return <tr><td colSpan="100" className="table-loading">Laddar...</td></tr>;
  } if (error) {
    return <tr><td colSpan="100" className="table-error">Upps! Något gick fel...</td></tr>;
  }

  return false;
}

LoadingTable.propTypes = {
  // query: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

function TableSection(props) {
  const { children } = props;
  return (
    <div className="table">
      <div className="container">
        {children}
      </div>
    </div>
  );
}

TableSection.propTypes = {
  children: PropTypes.array.isRequired,
};

function UserSearch(props) {
  const { onSearch } = props;
  const [isVisible, setIsVisible] = useState(true)

  const handleSumbit = (e) => {
    
    e.preventDefault();

    const inputValue = e.target.elements.searchInput.value;
    if(inputValue.length < 3){
      setIsVisible(false)

      setTimeout(() => {
        setIsVisible(true)
      }, 3000)

      return;
    }

    onSearch(inputValue)
  };

  return (
    <>
    <form className='table-search' onSubmit={handleSumbit}>
      <input 
        type="text" 
        name='searchInput'
        placeholder='Sök efter användare...' 
      />
      <button className='primary' type='submit'>Sök</button>
    </form>
    {!isVisible && <p className="table-error">Sökningen måste innehålla minst 3 tecken</p>}
    </>
  )
}

UserSearch.propTypes = {
  onSearch: PropTypes.func.isRequired
}

export { sortList, SortButton, EditButton, LoadingTable, EmptyList, TableSection, UserSearch };
