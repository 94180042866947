import React, { useEffect } from 'react'
import { useNavigationContext } from '../../context/NavigationContext';
import Table from './BookletsTable';
import { ConfirmationModalContextProvider } from '../../context/ConfirmationModalContext';

const Booklets = (props) => {
  const { zone: zoneId } = props.match.params;

  const { setNavigationActivated } = useNavigationContext();
  useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <ConfirmationModalContextProvider>
      <div id="booklets">
        <Table zoneId={zoneId} />
      </div>
    </ConfirmationModalContextProvider>
  )
}

export default Booklets
